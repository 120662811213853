import Vue from "vue";
import Vuex from "vuex";
import { NOKEY_WEB_ENV } from "@/configs/constants";
import { UPDATE_ENV, UPDATE_PROTYPE } from "./constants";
import appEnv, { compareVersion } from "../utils/env";
import { BASE_APP_NAV_VERSION } from "../utils/env";
import { i18n } from "@/main";
Vue.use(Vuex);
interface Env {
  isIOS: boolean; // 是否是ios
  deviceId: string; // 是否是安卓
  statusBarHeight: number; // 状态栏
  navBarHeight: number; // 导航栏高度
  navigationBarHeight: number; // 状态栏+导航栏高度
  brand: string; // 手机品牌
  isWx: boolean;
  isQq: boolean;
  isWxMini: boolean;
  model: string; // 机型
  pixelRatio: number; // 分辨率
  screenHeight: number; // 屏幕高度
  screenWidth: number; // 屏幕宽度
  version: string; // 手机app版本
  isNavHidden: number; // 是否隐藏导航栏
  appType: string; // 系统类型
  isAppWebView: boolean; // 是否在webview中
}
let envData: Partial<Env> = {
  isAppWebView: false,
  isIOS: false,
  isWx: false,
  deviceId: "",
  statusBarHeight: 20,
  model: "",
  pixelRatio: 3,
  isNavHidden: 0,
  screenHeight: window.screen.height,
  screenWidth: window.screen.width,
  appType: "",
  navigationBarHeight: 44,
  navBarHeight: 44,
  version: "",
};
function setInitWebEnv() {
  if (window.localStorage.getItem(NOKEY_WEB_ENV)) {
    envData = JSON.parse(window.localStorage.getItem(NOKEY_WEB_ENV) as string);
  }
  const result = appEnv;
  envData.deviceId = "ingeek-web";
  Object.assign(envData, result);
}
setInitWebEnv();
export default new Vuex.Store({
  state: {
    env: envData,
    proType: "", // "1".乘趣app "2".小程序-我的车钥匙项目 "3".小程序-巴顿lite项目
  },
  getters: {
    /* 导航栏高度，不包括状态栏
     * @param {*} state
     */
    navigationBarHeight(state) {
      return Number(state.env.navBarHeight || 44);
    },
    /**
     * @description 是否已经存在原生导航
     */
    hasNativeNavigationBar(state) {
      const version = (state.env.version as string)?.split(" ")?.[0].slice(1);
      const navVersion = compareVersion(version, BASE_APP_NAV_VERSION);
      const res =
        state.env.isWx || state.env.isQq || state.env.isWxMini || !navVersion;
      if (res) {
        return true;
      }
      return false;
    },
    /**
     * @description statusBarHeight
     */
    statusBarHeight(state) {
      const env = state.env;
      return Number(env.statusBarHeight);
    },
    /**
     * 导航栏上边距，根据当前是否是沉浸式计算
     * @param {*} state
     */
    navPaddingTop(state) {
      const env = state.env;
      return env?.isAppWebView ? Number(env.statusBarHeight || 0) : 0;
    },
  },
  mutations: {
    [UPDATE_ENV](state: any, data) {
      const newData = Object.assign(state.env, Object.assign(data));
      if (newData.language === "zh") {
        i18n.locale = "zh";
      }
      if (newData.language === "en") {
        i18n.locale = "en";
      }
      window.localStorage.setItem(NOKEY_WEB_ENV, JSON.stringify(newData));
      state.env = newData;
    },
    // 保存当前菜单栏的路径
    [UPDATE_PROTYPE](state: any, type: string) {
      state.proType = type;
    },
  },
});

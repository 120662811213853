import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Mint from "mint-ui";
import axios from "axios";
import VideoPlayer from "vue-video-player";
import * as utils from "@/utils";
import store from "./vuex";
import "mint-ui/lib/style.css";
import routerPlugin from "./plugins/router";
import { intApp } from "./ingeekJsApi";
import VueHead from "vue-head";
import VueI18n from "vue-i18n";
import en from "./locales/en";
import zh from "./locales/zh";
Vue.use(VueI18n);
Vue.use(VueHead);
// 播放器
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "./assets/js/vwjsbridge-v1.0.0.js";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);
// 全局样式
import "@/assets/scss/common-style.scss";
import "@/assets/scss/mint.scss";
// 创建 i18n 实例
const urlParams = new URLSearchParams(window.location.search);
export const i18n = new VueI18n({
  locale: !urlParams.get("language")
    ? "zh"
    : ["zh_CN", "zh"].includes(urlParams.get("language") || "")
    ? "zh"
    : "en", // 设置默认语言
  fallbackLocale: "en", // 设置备用语言
  messages: {
    en,
    zh,
  },
});
(window as any).webViewJavascriptBridge.init({
  key: "SVW-MOS-Ingeek",
});
Vue.config.productionTip = false;
Vue.use(Mint);
Vue.prototype.$util = utils;
Vue.use(VideoPlayer);
Vue.prototype.$axios = axios;
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err, info);
};
routerPlugin.install(Vue, {
  canUseNewWebView: false,
  newWebViewOptions: {},
  moduleName: "",
  store,
  router,
});
const renderVueApp = () => {
  new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
};
intApp().then(() => {
  renderVueApp();
});

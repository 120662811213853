export default {
  smartUnlock: "智能解闭锁",
  helpCenter: "帮助中心",
  commonProblems: "常见问题",
  popularFeature: "热门功能",
  desktopWidgets: "桌面快捷组件",
  serviceTime: "每日 09:00-21:00",
  servicePhone: "电话客服",
  resolved: "已解决",
  unresolved: " 未解决",
  widgetFeature: "小组件功能上线",
  iosSystemUpgrade: "系统需升级到ios14或以上版本",
  useIosStepOne: "1.长按主屏幕",
  useIosStepTwo: "2.点击左上方“+",
  useIosStepThree: "3.选择“乘趣”小组件,添加至桌面",
  shortVehicleOffline: "车辆不在线",
  shortVehicleOfflineQ: "手机网络正常但App提示“车辆不在线”",
  shortSmartUnlock: "智能解闭锁不灵敏",
  shortSmartUnlockQ: "靠近车辆不自动解锁，离开车辆不自动上锁",
  shortControlBle: "蓝牙连不上",
  shortControlBleQ: "靠近车辆，打开数字钥匙提示“蓝牙未连接”",
  shortVehicleControl: "车控执行失败",
  shortVehicleControlQ: "用数字钥匙执行车控，车控执行失败",
  shortFastPower: "无法“一键启动”",
  shortFastPowerQ: "上车后使用“一键启动”按钮启动车辆，仪表盘提示“未检测到钥匙”",
  shortOta: "OTA升级失败",
  shortOtaQ: "升级固件时，提示升级失败、升级超时",
  questionVehicleNotOnline: "车辆不在线-车辆未联网，无法远程控车",
  problemDescription: "问题现象：",
  vehicleOffline: "手机网络正常，但App提示“车辆不在线”",
  solution: "解决方案：",
  offlineMethodOne:
    " 车辆使用的是物联网网络，有些区域物联网的网络信号弱，此时车辆物联网受限，建议您行驶至其它区域后再试，如果无论在什么区域，APP始终显示车辆不在线，那么建议您进入数字钥匙模块，点击重启数字钥匙，待重启后看是否能恢复",
  offlineMethodTwo:
    " 如果会员到期，4G功能会关闭，建议您先进行续费，续费后4G功能恢复",
  offlineMethodThree:
    "当以上操作都无法恢复时，请靠近车辆待蓝牙连接后进入车辆设置->车辆网络信号诊断，点击开始诊断，诊断后拨打客服热线反馈",
  smartUnlockIssue: " 智能解闭锁不灵敏",
  smartUnlockPhenomenon: "触摸门把手解闭锁不灵敏，智能解闭锁不灵敏",
  smartUnlockMethodOne:
    "部分手机因自身的原因，无法100%的做到蓝牙自动连接，就像我们使用蓝牙耳机，也会偶尔出现无法自动连接上的情况。遇到此种情况请打开App，然后手动点击解闭锁",
  smartUnlockMethodTwo: "根据App中引导，自行调节灵敏度，建议多尝试几次",
  smartUnlockMethodThree:
    "尾门处的蓝牙信号弱，数字钥匙误认为用户已远离车辆，通过将闭锁距离调远一点，可以一定程度上避免此问题的发生",
  smartUnlockMethodFour:
    "智能闭锁同样依赖蓝牙连接，若您离开车时蓝牙未连接，远离车将无法触发闭锁，此时请打开App手动闭锁",
  smartUnlockMethodFive: "以上操作都无法解决，请拨打热线客服反馈",
  bluetoothNotConnecting: "蓝牙连不上",
  bluetoothNotConnectingPhoenomenon:
    "靠近车，数字钥匙模块页面蓝牙图标灰显，进入数字钥匙模块，上方提示'蓝牙未连接'",
  bluetoothNotConnectingMethodOne:
    "只有在车辆周围才能连上蓝牙，不在车周围无法连上车辆蓝牙",
  bluetoothNotConnectingMethodTwo:
    "如果APP出现蓝牙修复提示，请您根据提示完成蓝牙修复",
  bluetoothNotConnectingMethodThree:
    "如果您在车辆旁边但APP始终显示蓝牙未连接，那么你可尝试以下三种操作： ",
  bluetoothNotConnectingMethodThreeOne:
    "a.进入系统蓝牙，找到ikey开头的设备，点击取消配对，然后打开App等待弹出配对，点击配对进行连接",
  bluetoothNotConnectingMethodThreeTwo: "b.进入系统蓝牙,开关下蓝牙开关 ",
  bluetoothNotConnectingMethodThreeThree: "c.重启手机",
  bluetoothNotConnectingMethodFour:
    "当以上操作都无法恢复时，请拨打客服热线反馈",
  controlExecutionFailed: "车控执行失败",
  controlExecutionFailedPhoenomenon: "在数字钥匙上点击车控，车端没有执行成功",
  controlExecutionFailedMethodOne:
    "如果点击车控按钮，在loading完成后，车辆没有任何反应且App没有任何提示，说明车控指令已发送成功，但车未执行，偶尔车控执行失败属于正常现象，建议多尝试几次",
  controlExecutionFailedMethodTwo:
    "如果远程点击车控按钮，在loading完成后，APP端出现“车机无响应”的提示，一般是信号问题导致，建议您多试几次或靠近车使用蓝牙车控",
  controlExecutionFailedMethodThree:
    "如果在车辆周围点击车控按钮，在loading完成后，APP端出现“车机无响应”的提示，请拨打客服热线反馈",
  oneClickStartFailed: "无法“一键启动”",
  oneClickStartNoKeyDetectedPhoenomenon:
    "上车后使用“一键启动”按钮发动车辆，仪表盘提示“未检测到钥匙”",
  oneClickStartNoKeyDetectedMethodOne:
    "针对部分车型，普通模式下，只要通过数字钥匙解锁车辆，上车就可以一键启动发动车辆，如果仪表盘出现“未检测到钥匙”的提示，大概率是触发了闭锁或不是用数字钥匙解的锁，此时只要打开APP手动解锁一次车辆再按键发动车辆即可",
  oneClickStartNoKeyDetectedMethodTwo:
    "针对部分车型，严格模式下，只要蓝牙连接着，上车就可以一键启动发动车辆，如果仪表盘出现“未检测到钥匙”的提示，请检查车辆蓝牙的连接状态",
  oneClickStartNoKeyDetectedMethodThree:
    "当以上操作都无法恢复时，请拨打客服热线反馈",
  otaUpgradeFailed: " OTA升级失败",
  otaUpgradeFailedPhoenomenon:
    "有推送升级的情况下，进入数字钥匙模块->OTA-固件升级->点击升级到最新版本，提示升级失败",
  otaUpgradeFailedMethodOne: "确保车辆静止状态下，多次点击升级，多尝试几次",
  otaUpgradeFailedMethodtwo:
    "当车辆处于信号较弱位置时，远程升级可能存在失败的情况，建议靠近车待蓝牙连接后，再尝试升级",
  otaUpgradeFailedMethodThree:
    "进入数字钥匙模块，点击重启数字钥匙，重启后再次尝试",
  otaUpgradeFailedMethodFour: "当以上操作都升级失败时，请拨打客服热线反馈",
  widgetFeatureOnline: "小组件功能上线",
  longPressHomeScreen: "长按主屏幕",
  tapPlusIcon: "点击左上方“+”",
  selectRideFunWidget: "选择“乘趣”小组件,添加至桌面",
  iosVersionRequirement: "系统需升级到ios14或以上版本",
  tapAddWidget: "点击添加小组件/窗口小工具",
  addRideFunWidget: "选择“乘趣”小组件,添加至桌面",

  welcomeSmartUnlock: "欢迎使用智能解闭锁",
  smartUnlockDescription: "智能解闭锁为创新实验功能，存在一定的不稳定性",
  smartUnlockDescriptionTwo: "请在仔细了解后选择是否开启",
  aboutConnection: "关于连接",
  approachVehicle:
    "靠近车辆，手机与车将自动建立蓝牙连接，车辆将通过蓝牙信号判断手机与车之间的距离，<strong>进入解锁区域后，将自动解锁，远离车辆将自动闭锁。</strong>",
  unableToAutoUnlock: "若靠近车辆无法自动解锁?",
  connectionIssueExplanation:
    "部分手机无法做到每次都连接成功，就像我们用蓝牙耳机，也会偶尔出现无法自动连上的情况。若自动连接失败，将无法触发智能解锁，<strong>此时请打开APP选择手动解锁。</strong>",
  aboutSensitivity: "关于不灵敏",
  adjustSensitivityExplanation:
    "若出现解锁不灵敏的情况，如靠近车辆等待几秒才能解锁，<strong>可自行调节灵敏度以达到最佳的解锁体验。</strong>",
};

<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <keep-alive include="searchQuestion,allQuestion">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { UPDATE_ENV } from "./../src/vuex/constants.ts";
export default {
  data() {
    return {
      deviceId: "",
      source: "",
      language: "",
    };
  },
  methods: {
    ...mapMutations({
      updateEnv: UPDATE_ENV,
    }),
    // 初始化web环境
    dealWebAppEnv() {
      this.deviceId = this.$route.query.deviceId;
      this.source = this.$route.query.source;
      this.language = this.$route.query.language;
      this.updateEnv({
        deviceId: this.deviceId,
        source: this.source,
        language: this.language,
      });
    },
  },
  watch: {
    $route: {
      handler() {
        if (!this.deviceId) {
          this.$nextTick(() => {
            this.dealWebAppEnv();
          });
        }
      },
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
